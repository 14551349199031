button:focus {
  outline: none !important;
}

.highlight {
  /*background: #ededed;*/
  animation: cardAnimation 1s ease-in-out forwards;
}

@keyframes cardAnimation {
  from{
    background: #9b9b9b;
  }
  to{
    background:  #ffffff;
  }
}